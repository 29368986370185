
.main_footer {
    background-color: #06093d !important;
    color: white;
    font-size: 18px;
    font-weight: 600;

    & .details_container {
        padding: 0px 20px;
        font-size: 50px;
        color: white;

        & .left_detail {
            border-right: 2px solid white;
            padding-bottom: 10px;
            & .contact_details {
                margin-top: 15px;
                font-size: 16px;
                font-weight: 400;
            }
        }

        & .right_detail {
            padding-left: 50px;
            padding-bottom: 10px;
            & .contact_details {
                margin-top: 15px;
                font-size: 16px;
                font-weight: 400;
            }
        }

        & .social_icons{
            margin-top: 25px;
            font-size: 20px;
            & i {
                border: 1.5px solid #fff;
                border-radius: 5px;
                padding: 5px;
            }
        }
        & .working_title {
            margin-top: 25px;
            font-size: 25px;
        }
        & .working_time {
            font-size: 16px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
        }
      }
}

.subFooter_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 75px;
    background-color: #06093d;
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-top: 1px solid #ffffff70;
}


@media screen and (max-width: 768px) {

    .main_footer {
        & .details_container {
            flex-direction: column;
            & .left_detail {
                width: 100%;
                border: none;
                & .detail_title {
                    font-size: 30px;
                }
            }

            & .right_detail {
                width: 100%;
                padding-left: 20px;
                & .detail_title {
                    font-size: 30px;
                }
            }
        }
    }

    .subFooter_container {
        padding: 20px 10px;
        justify-content: center;
    }
}