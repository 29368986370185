.images_container {
  & .images {
    & img {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }
  }
}

.service_details_container {
  font-family: Poppins, sans-serif;
  color: #020e26;
  margin-bottom: 50px;
  background-color: white;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  & .name {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  & .title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  & .content {
    font-size: 16px;
    font-weight: 500;
  }

  & .description {
    font-size: 14px;
    font-weight: 400;
  }

  & .button_container {
    margin-top: 20px;
    & .service_btn {
      background-color: #0e1680;
      color: #fff;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 5px;

      transition: all 0.5s ease-in-out;
      border: 2px solid #0e1680;

      &:hover {
        background-color: #fff;
        color: #0e1680;
        border: 2px solid #0e1680;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .service_details_container {
    & .name {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .service_details_container {
    & .name {
      font-size: 25px;
    }
  }
}
