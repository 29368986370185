.page_title {
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 50px;
    color: white;
}

@media screen and (max-width: 768px) {
    .page_title {
        font-size: 35px;
    }
}