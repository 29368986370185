.header_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: auto !important;

  & .book_button {
    background-color: #0e1680;
    color: white;
    padding: 7px 12px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    & i {
      color: lightgreen;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.homeless_header {
  .navbar-nav > .nav-item {
    & > .nav-link {
      color: #fff;
    }

    &.active {
      background-color: #f9f9fb2c;
      border-bottom: 1px solid #f9f9fb;
      > .nav-link {
        opacity: 1;
        color: #fff;
      }
    }
    &:hover {
      background-color: #f9f9fb2c;
      border-bottom: 1px solid #f9f9fb;
    }
  }

  .social-icon-style-01.custom a {
    color: #fff !important;
  }
}

@media only screen and (max-width: 1365px) {
  .navbar {
    padding: 0px 10px !important;
  }

  .nav-link {
    margin: 0px 10px !important;
  }

  .header_button {
    gap: 12px;
  }
  .icons {
    display: none !important;
  }
}

@media only screen and (max-width: 1025px) {
  .navbar {
    padding: 0px 10px !important;
  }

  .nav-link {
    margin: 0px 10px !important;
  }

  .header_button {
    gap: 12px;
  }
  .icons {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .homeless_header {
    .navbar-nav > .nav-item {
      & > .nav-link {
        color: #232323;
      }

      &.active {
        background-color: #f9f9fb2c;
        border-bottom: 1px solid #f9f9fb;
        > .nav-link {
          opacity: 1;
          color: #232323;
        }
      }
      &:hover {
        color: #232323;
        background-color: #f9f9fb2c;
        border-bottom: 1px solid #f9f9fb;
      }
    }

    .social-icon-style-01.custom a {
      color: #232323 !important;
    }
  }
}

@screen sm {
  .header_button {
    & .book_button {
      background-color: #0e1680 !important;
      color: #fff !important;
    }
  }
}
